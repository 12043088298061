import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import contact from '../../assets/images/contact.png'
import addToMailchimp from 'gatsby-plugin-mailchimp'

function submitMailchimp(event) {
    event.preventDefault();
    const email = document.getElementById('subEmail').value;
    const name = document.getElementById('subName').value;
    addToMailchimp(email, {MERGE1: name, MERGE4: 'English'}).then(data => {
      document.getElementById('subMessage').innerHTML = data.msg;
    });
}

const ContactForm = () => {
    return (

        <section className="contact-area pb-100">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="contact" /> 
                        Contact us
                    </span>
                    <h2>Ready to start?</h2>
                    <p>Your data will be treated in accordance with the GDPR. Mandatory fields are marked with *</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="contact-image">
                            <img src={contact} alt="contact" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="contact-form">
                            <form method="post" action="https://getform.io/f/08970cc3-632d-4da5-9721-47cee23183df" id="contactForm">
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" name="name" className="form-control" required placeholder="Name *" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="email" name="_replyto" className="form-control" required placeholder="Email address *" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input type="text" name="phone_number" className="form-control"  placeholder="Phone number" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea name="message" className="form-control" cols="30" rows="6" required placeholder="Write your message *" />
                                        </div>
                                    </div>
                                    <div className="col-lg-11 col-md-11">
                                    <label style={{fontSize: '14px', padding: '5px'}}>
                                            (I have read and I accept the <a href="/privacy-policy">privacy policy</a>)
                                        </label>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <button type="submit" className="default-btn">
                                            <i className="flaticon-tick"></i> 
                                            Submit messagge <span></span>
                                        </button>
                                    </div>
                                </div>
                            </form>                            
                        </div>
                    </div>
                </div>
                &nbsp;
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="contact" /> 
                        Gnusletter
                    </span>
                    <h2>Keep in touch</h2>
                    <p>
                    No spam and you can unsubscribe whenever you want.
                    Your data will be treated in accordance with the GDPR.
                    Mandatory fields are marked with *
                    </p>
                </div>

                <div className="row">


                    <div className="col-lg-6 col-md-12">
                        <div className="contact-form">
                            <form id="GnusletterForm">
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" id="subName" name="gnuname" className="form-control" required placeholder="Name *" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="email" id="subEmail" name="gnuemail" className="form-control" required placeholder="Email address *" />
                                        </div>
                                    </div>
                                    <div className="col-lg-11 col-md-11">
                                    <label style={{fontSize: '14px', padding: '5px'}}>
                                            (I have read and I accept the <a href="/privacy-policy">privacy policy</a>)
                                    </label>                                    
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                    <button type="submit" className="default-btn" onClick={submitMailchimp}>
                                            <i className="flaticon-tick"></i>Subscribe now!</button>
                                        <p id="subMessage" style={{marginTop: '10px'}}></p>
                                    </div>
                                </div>
                            </form>                            
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="contact-image">
                            <img src={contact} alt="contact" />
                        </div>
                    </div>

                </div>

            </div>
        </section>
    )
}

export default ContactForm

