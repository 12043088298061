import React from 'react'

const ContactInfo = () => {
    return (
        <div className="contact-info-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-map'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-map'></i>
                            </div>
                            <h3>Milano HQ</h3>
                            <p>via Stefanardo da Vimercate 28,<br></br> 20128 Milano</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <h3>Contacts</h3>
                            <p>Mobile: <a href="tel:+393703309574">(+39) - 370 3309574</a></p>
                            <p>E-mail: <a href="mailto:accounts@gnucoop.com"> Administration</a></p>
                            <p>E-mail: <a href="mailto:info@gnucoop.com"> Projects</a></p>
                            <p>E-mail: <a href="mailto:academy@gnucoop.com"> Training</a></p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-map'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-map'></i>
                            </div>
                            <h3>Pisa HQ</h3>
                            <p>via Manzoni 10, <br></br> 56125 Pisa</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo